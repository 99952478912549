import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AuthService } from '@services/auth.service';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { SessionHelper } from '@helpers/session.helper';
import { FormNotifierService } from '@services/form-notifier.service';
import { ICountry } from '@interfaces/ICountry';
import { DiscountResource } from '@components/discount/resources/discount.resource';
import { DISCOUNT_TYPES } from '@components/automatic-discount/list/services/automatic-discount-filters-form.service';
import { MarketplaceHelper } from '@helpers/MarketplaceHelper';
import {
  IDiscountFormGeneralModel,
  IDiscountTranslations, IDiscountTypeAutomatic,
  IFormBody,
  IFormValue,
  ITranslationsFormValue
} from '@components/automatic-discount/interfaces/discount-form.interface';
import * as moment from 'moment';
import { DATE_FULL_FORMAT, DATE_SHORT_FORMAT } from '@constants';
import { takeUntil } from 'rxjs/operators';
import {CREATION_PAGE, EDITION_PAGE, ITreeCategory} from '@interfaces';
import { HydraHelper } from '@helpers/HydraHelper';
import { DiscountTranslationResource } from '@components/discount/resources/discount-translation.resource';
import { AutomaticDiscountResource } from '@components/automatic-discount/resources/automatic-discount.resource';
import { ProductResource } from '@components/product/product.resource';
import { SnackbarService } from '@components/snackbar';
import { AbstractResource } from '@resources/abstract.resource';
import {AbstractPageComponent} from '@components/generic/abstract-page.component';
import {IProduct} from '@components/discount/interfaces/discount-form.interface';
import {ICategories} from '@components/categories/models';
import {CategoryTreeHelper} from '@helpers';
import {CategoryResource} from '@resources';

@Component({
  selector: 'app-automatic-discount-form',
  template: require('./automatic-discount-form.component.html'),
  providers: [
    { provide: AbstractResource, useClass: DiscountResource },
  ]
})
export class AutomaticDiscountFormComponent extends AbstractPageComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public readonly discountTypesList = [
    { label: this.translate('PAGE.DISCOUNT_AUTOMATIC.FILTER.TYPE.DATA.MINIMUM_PRODUCTS'), value: DISCOUNT_TYPES.discount_minimum_products },
    { label: this.translate('PAGE.DISCOUNT_AUTOMATIC.FILTER.TYPE.DATA.SECOND_PRODUCT'), value: DISCOUNT_TYPES.discount_second_product },
    { label: this.translate('PAGE.DISCOUNT_AUTOMATIC.FILTER.TYPE.DATA.FREE_PRODUCT'), value: DISCOUNT_TYPES.free_product }
  ];
  protected products$: Observable<any>;
  protected products: any[];
  protected categories: ITreeCategory[];
  public readonly currency = SessionHelper.getCurrency().toLowerCase();
  public isReadOnly = false;
  public startTime: Date;
  public endTime: Date;
  protected currentCountry: ICountry = SessionHelper.getCountry();
  protected discountAutomatic: any = null;

  get translationsFA(): FormArray {
    return this.form.get('translations') as FormArray;
  }

  @Input() public readOnly: boolean;
  @Input() public model: IDiscountFormGeneralModel;

  @Output() public validNameField: EventEmitter<any> = new EventEmitter();
  @Output() public setReadOnly: EventEmitter<any> = new EventEmitter();

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    resource: AbstractResource,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    private productResource: ProductResource,
    private snackbar: SnackbarService,
    private formNotifier: FormNotifierService,
    @Inject('DialogService') private dialog: any,
    private discountAutomaticResource: AutomaticDiscountResource,
    private discountTranslationResource: DiscountTranslationResource,
    private categoryResource: CategoryResource,
    @Inject('CountryService') private countryService: any,
  ) {
    super($translate, authService, resource, state);
  }

  /**
   * @inheritDoc
   */
  ngOnInit(): void {
    this.products$ = this.productResource.getByCountryCodeAndSku(
      SessionHelper.getCountry().code,
      undefined,
      { 'marketplaces[]': MarketplaceHelper.getWebsiteMarketplace().code },
      { dontUseModel: true, blocking: false },
    );

    this.categoryResource.cGet({ level: 1, marketplace: MarketplaceHelper.getWebsiteMarketplace().code })
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (response: ICategories[]) => this.categories = CategoryTreeHelper.flatten(this.decodeCategories(response))
      );

    this.getIncludedProducts();

    if (EDITION_PAGE === this.pageType) {
      const id = this.state.params.id;

      this.resource.get(id, { entryPoint: `/v2/discounts/${id}`})
          .pipe(
              takeUntil(this.destroyed$)
          )
          .subscribe((response: any) => {
            this.model = response;
            if (this.model.country && this.model.country.code !== SessionHelper.getCountry().code) {
              this.countryService.changeCountry(this.model.country.code);
            }

            this.buildForm();
          })
      ;

      return;
    }

    this.buildForm();
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      id: [this.model ? this.model.discountAutomatic.id : null],
      type: [this.model ? this.model.discountAutomatic.type : '', Validators.required],
      minimumProducts: [this.model ? this.model.discountAutomatic.minimumProducts : null],
      amount: [this.model ? this.model.discountAutomatic.amount : null],
      percentage: [this.model ? this.model.discountAutomatic.percentage : null],
      name: [this.model ? this.model.name : null],
      active: [this.model ? this.model.active : false],
      combinable: [this.model ? this.model.combinable : false],
      excludeSales: [this.model ? this.model.excludeSales : false],
      highlight: [this.model ? this.model.highlight : false],
      dateBegin: [this.model && this.model.dateBegin ? moment(this.model.dateBegin).format(DATE_SHORT_FORMAT) : null, Validators.required],
      dateEnd: [this.model && this.model.dateEnd ? moment(this.model.dateEnd).format(DATE_SHORT_FORMAT) : null, Validators.required],
      includedProducts: [this.model ? this.model.includedProducts : []],
      excludedProducts: [this.model ? this.model.excludedProducts : []],
      includedCategories: [this.model ? this.model.includedCategories : []],
      excludedCategories: [this.model ? this.model.excludedCategories : []],
    });

    this.buildTranslationsForm();
    this.form.setValidators(this.checkIncludedExcludedSameTime());
  }

  private buildTranslationsForm(): void {
    // build several form group for each country locales
    const formGroups = this.currentCountry.locales.map((locale: string) => {
      let translation: any = null;
      let id: string = null;
      let title: string = null;
      let color: string = null;

      if (this.model) {
        translation = this.model.discountAutomatic.translations[locale];
        id = translation && translation.id ? translation.id : null;
        title = translation ? translation.title : null;
        color = translation ? translation.color : null;
      }

      return this.formBuilder.group({
        id: id,
        locale,
        title: title,
        color: color
      });
    });

    // pass the form groups to a form array
    const formArray = this.formBuilder.array(formGroups);

    // replace existing control with a new one, we pass it the form array that is a form group collection
    this.form.setControl('translations', formArray);
  }

  public submit(event?: any): void {
    this.dialog.confirm(this.translate('PAGE.DISCOUNT.FORM.CONFIRM_SAVE'))
      .then(async () => {
        const body: IFormBody = await this.prepareBody();
        const observable$ = EDITION_PAGE === this.pageType ?
          (<DiscountResource>this.resource).updateDiscount(this.state.params.id, body) :
          (<DiscountResource>this.resource).createDiscount(body)
        ;

        if (!body.name) {
          this.validNameField.emit(false);

          return;
        }

        this.validNameField.emit(true);

        observable$
          .pipe(
            takeUntil(this.destroyed$)
          )
          .subscribe((response: any) => {
            CREATION_PAGE === this.pageType ?
              this.createTranslations(response) :
              this.currentCountry.locales.map((locale: string) => {
                if (undefined !== response.discountAutomatic.translations[locale]) {
                  this.updateTranslations(response.discountAutomatic, locale);
                } else {
                  this.createTranslations(response);
                }
              });

            this.snackbar.validate(this.translate('ALERTS.FORM.SAVED'));
            this.formNotifier.notifyFormSubmitted();

            if (event && event.redirect) {
              this.state.go(`automatic-discount.list`);

              return;
            }

            this.state.go(`automatic-discount.edit`, {id: response['@id'].split('/').pop()});
          })
        ;
      })
    ;
  }

  private createAutomaticDiscount(formValue: IFormValue): Promise<void> {
    return this.discountAutomaticResource.create({
      type: formValue.type,
      minimumProducts: formValue.minimumProducts,
      percentage: formValue.percentage,
      amount: formValue.amount,
      translations: this.getFormTranslations(),
      lowestProduct: formValue.type === 'discount_minimum_products'
    })
      .toPromise()
      .then((response: any) => {
        this.discountAutomatic = response['@id'];
      });
  }

  private updateAutomaticDiscount(automaticId: string, formValue: IFormValue): Promise<void> {
    return this.discountAutomaticResource.partialUpdate(automaticId, {
      type: formValue.type,
      minimumProducts: formValue.minimumProducts,
      percentage: formValue.percentage,
      amount: formValue.amount,
      translations: this.getFormTranslations(),
    })
      .toPromise()
      .then((response: any) => {
        this.discountAutomatic = response['@id'];
      });
  }

  private createTranslations(discount: any): void {
    this.currentCountry.locales.map((locale: string) => {
      this.discountTranslationResource.createDiscountTranslation({
        translatable: discount['@id'],
        locale: locale,
      }).toPromise()
        .then(() => {
        });
    });
  }

  private updateTranslations(translation: any, locale: string): void {
    if (translation.locale === locale) {
      this.discountTranslationResource.updateDiscountTranslation(translation.translations[locale]['@id'].split('/').pop(), {
        locale: translation.locale,
        errorMessage: translation.errorMessage
      }).toPromise()
        .then(() => {
        })
      ;
    }
  }

  private async prepareBody(): Promise<{
    active: boolean;
    dateBegin: string;
    dateEnd: string;
    name: string;
    discountAutomatic: IDiscountTypeAutomatic
    country: string;
    highlight: boolean;
    translations: any;
    combinable: boolean;
    includedProducts: any;
    excludedProducts: any;
    includedCategories: any;
    excludedCategories: any;
  }> {
    const formValue: IFormValue = this.form.value;
    let dateBegin;
    let dateEnd;

    if (formValue.dateBegin) {
      if (this.startTime) {
        dateBegin = moment(formValue.dateBegin, DATE_SHORT_FORMAT)
          .add(this.startTime.getHours(), 'hours')
          .add(this.startTime.getMinutes(), 'minutes')
          .add(this.startTime.getSeconds(), 'seconds')
          .format(DATE_FULL_FORMAT)
        ;
      } else {
        dateBegin = moment(formValue.dateBegin, DATE_SHORT_FORMAT).startOf('day').format(DATE_FULL_FORMAT);
      }
    }

    if (formValue.dateEnd) {
      if (this.endTime) {
        dateEnd = moment(formValue.dateEnd, DATE_SHORT_FORMAT)
          .add(this.endTime.getHours(), 'hours')
          .add(this.endTime.getMinutes(), 'minutes')
          .add(this.endTime.getSeconds(), 'seconds')
          .format(DATE_FULL_FORMAT)
        ;
      } else {
        dateEnd = moment(formValue.dateEnd, DATE_SHORT_FORMAT).endOf('day').format(DATE_FULL_FORMAT);
      }
    }

    CREATION_PAGE === this.pageType ? await this.createAutomaticDiscount(formValue) : await this.updateAutomaticDiscount(formValue.id, formValue);

    return {
      active: formValue.active,
      dateBegin: dateBegin,
      dateEnd: dateEnd,
      name: formValue.name,
      discountAutomatic: this.discountAutomatic,
      country: HydraHelper.buildIri(SessionHelper.getCountry().id, 'countries'),
      translations: formValue.translations,
      highlight: formValue.highlight,
      combinable: formValue.combinable,
      includedProducts: formValue.includedProducts.length > 0 ? this.prepareIncludedProducts(formValue) : [],
      excludedProducts: formValue.excludedProducts.length > 0 ? this.prepareExcludedProducts(formValue) : [],
      includedCategories: formValue.includedCategories.length > 0 ? this.prepareIncludedCategories(formValue) : [],
      excludedCategories: formValue.excludedCategories.length > 0 ? this.prepareExcludedCategories(formValue) : [],
    };
  }

  private prepareIncludedProducts(formValue: IFormValue): string[] {
    if (formValue.includedProducts) {
      return formValue.includedProducts.map((product: { id: string; label: string }): string => product.id ? HydraHelper.buildIri(product.id, 'products') : '');
    }
  }

  private prepareExcludedProducts(formValue: IFormValue): string[] {
    if (formValue.excludedProducts) {
      return formValue.excludedProducts.map((product: { id: string; label: string }): string => product ? HydraHelper.buildIri(product, 'products') : '');
    }
  }

  private prepareIncludedCategories(formValue: IFormValue): string[] {
    if (formValue.includedCategories) {
      return formValue.includedCategories.map((category: { id: string }): string => HydraHelper.buildIri(category, 'categories'));
    }
  }

  private prepareExcludedCategories(formValue: IFormValue): string[] {
    if (formValue.excludedCategories) {
      return formValue.excludedCategories.map((category: { id: string }): string => HydraHelper.buildIri(category, 'categories'));
    }
  }

  private getFormTranslations(): IDiscountTranslations {
    const translations: IDiscountTranslations = {};

    this.translationsFA.controls.forEach((formGroup: FormGroup) => {
      const translationsFormValue: ITranslationsFormValue = formGroup.value;

      if (translationsFormValue.id) {
        translations[translationsFormValue.locale] = {
          'id': HydraHelper.buildIri(translationsFormValue.id, 'discount_automatic_translations'),
          locale: translationsFormValue.locale,
          title: translationsFormValue.title,
          color: translationsFormValue.color
        };
      } else {
        translations[translationsFormValue.locale] = {
          locale: translationsFormValue.locale,
          title: translationsFormValue.title,
          color: translationsFormValue.color
        };
      }
    });

    return translations;
  }

  public hasSeveralTranslations(): boolean {
    return this.currentCountry.locales.length > 1;
  }

  public getIncludedExcludedRuleHelp(): any {
    /* tslint:disable:no-bitwise */
    const discountIncludeExcludeRules = (+(this.form.get('includedProducts').value.length !== 0) * 1)
      | (+(this.form.get('excludedProducts').value.length !== 0) * 2)
      | (+(this.form.get('includedCategories').value.length !== 0) * 4)
      | (+(this.form.get('excludedCategories').value.length !== 0) * 8);
    /* tslint:enable:no-bitwise */

    return [0, 1, 2, false, 3, 5, 6, false, 4, 7, 8, false, false, false, false, false][discountIncludeExcludeRules];
  }

  protected getIncludedProducts(query?: any): void {
    this.productResource.getProductsLight(
      { sku: query ? query : null,
        'countryCode': SessionHelper.getCountry().code,
        'marketplace': MarketplaceHelper.getWebsiteMarketplace().code, pagination: true },
      { dontUseModel: true, blocking: false, isHydra: true, returnHydraMembers: true }
    )
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any) => {
        this.products = response.filter((product: IProduct) => !!product.sku);
      });
  }

  private multipleSkusParser(query: string): string[] {
    const skus = [];
    const regex = new RegExp('([A-Z0-9a-z\\+\\-_]+)(?:,|;)?', 'gm');
    let match;

    while ((match = regex.exec(query)) !== null) {
      if (match.index === regex.lastIndex) {
        regex.lastIndex++;
      }

      skus.push(match[1]);
    }

    return skus.filter((value: any, index: number, self: any[]) => {
      return self.indexOf(value) === index;
    });
  }

  private decodeCategories(categories: ICategories[]): any {
    if (!categories) {
      return null;
    }

    return categories.map((category: any) => ({
      children: this.decodeCategories(category.children),
      id: category.id,
      label: category.translations[this.currentLocale] ? category.translations[this.currentLocale].name : ''
    }));
  }

  public checkIncludedExcludedSameTime(): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors | null => {
      if (formGroup.get('includedProducts').value.length !== 0 && formGroup.get('excludedProducts').value.length !== 0) {
        formGroup.get('includedProducts').setErrors({ 'includedExcluded': true });
        formGroup.get('excludedProducts').setErrors({ 'includedExcluded': true });
      } else {
        formGroup.get('includedProducts').setErrors(null);
        formGroup.get('excludedProducts').setErrors(null);
      }

      if (formGroup.get('includedCategories').value.length !== 0 && formGroup.get('excludedCategories').value.length !== 0) {
        formGroup.get('includedCategories').setErrors({ 'includedExcluded': true });
        formGroup.get('excludedCategories').setErrors({ 'includedExcluded': true });
      } else {
        formGroup.get('includedCategories').setErrors(null);
        formGroup.get('excludedCategories').setErrors(null);
      }

      return null;
    };
  }
}
