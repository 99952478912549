import { AbstractPageComponent } from '@components/generic/abstract-page.component';
import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SessionHelper } from '@helpers';
import { AbstractResource } from '@resources';
import { CarrierCapacityResource } from '@resources/carrier-capacity.resource';
import { WarehouseCarrierResource } from '@resources/warehouse-carrier.resource';
import { IWarehouses } from '@components/warehouses/models';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { DATE_SHORT_FORMAT, DATE_SHORT_FORMAT_UK } from '@constants';
import { SnackbarService } from '@components/snackbar';
import { Observable } from 'rxjs/Observable';
import { CarrierGroupAgencyResource } from '@components/carrier/mesh-agency/carrier-group-agency.resource';

@Component({
  selector: 'app-carrier-capacity-form',
  template: require('./carrier-capacity-form.component.html'),
  styles: [require('./carrier-capacity-form.component.scss')],
  providers: [
    { provide: AbstractResource, useClass: CarrierCapacityResource },
  ],
})
export class CarrierCapacityFormComponent extends AbstractPageComponent implements OnInit {
  public warehouseForm: FormGroup;
  public capacityForm: FormGroup;
  public agencyForms: FormGroup[] = [];
  public warehouse: any;
  public warehouses: any[] = SessionHelper.getAllWarehouses();
  public daysOfWeek: any[] = [];
  public warehouseCarriers: any[] = [];
  public carrierGroupAgencies: any = [];
  public capacity: any;

  constructor(
    @Inject('TranslationService') $translate: ng.translate.ITranslateService,
    authService: AuthService,
    @Inject('StateService') state: ng.ui.IStateService,
    private formBuilder: FormBuilder,
    private carrierCapacityResource: CarrierCapacityResource,
    private warehouseCarrierResource: WarehouseCarrierResource,
    private carrierGroupAgencyResource: CarrierGroupAgencyResource,
    private snackbar: SnackbarService
  ) {
    super($translate, authService, null, state);
  }

  ngOnInit(): void {
    this.setDaysOfWeek();
    this.buildWarehouseForm();
    this.setWarehouse('lsl');

    if (this.state.params.id) {
      this.fetchCapacity(this.state.params.id);
    } else {
      this.onWarehouseChange();
    }
  }

  public setDaysOfWeek(): void {
    this.daysOfWeek = [{ dayOfWeek: -1, day: '' }];

    for (let i: number = 0; i < 7; ++i) {
      this.daysOfWeek.push({ dayOfWeek: i, day: this.translate('DAYS.' + (i + 1)) });
    }
  }

  public buildWarehouseForm(): void {
    this.warehouseForm = this.formBuilder.group({
      warehouse: [null],
    });
  }

  public buildCapacityForm(): void {
    let warehouseCarriers: string[] = [];

    if (this.capacity) {
      warehouseCarriers = this.capacity.warehouseCarriers.map((wc: any) => {
        return wc['@id'];
      });
    }

    this.capacityForm = this.formBuilder.group({
      warehouseCarriers: [warehouseCarriers],
      dayOfWeek: [this.capacity ? this.capacity.dayOfWeek : null],
      date: [this.capacity && this.capacity.date ? moment(this.capacity.date).format(DATE_SHORT_FORMAT) : null],
      daysOfExport: [this.capacity ? this.capacity.daysOfExport : []],
      capacity: [this.capacity ? this.capacity.capacity : null],
      noPickup: [this.capacity ? !this.capacity.pickup : false],
      scale: [this.capacity ? this.capacity.scale : false],
      injection: [this.capacity && this.capacity.carrierGroupAgencyCapacities.length],
    });

    this.setInjection();
  }

  public setWarehouse(code: string): void {
    this.warehouseForm.get('warehouse').setValue(this.warehouses.find((warehouse: IWarehouses): boolean => {
      return warehouse.code === code;
    })['@id']);
  }

  public fetchCapacity(id: string): void {
    this.carrierCapacityResource.get(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any): void => {
        this.capacity = response;
        this.setWarehouse(this.capacity.warehouseCarriers[0].warehouse.code);
        this.onWarehouseChange();
      })
    ;
  }

  public onWarehouseChange(): void {
    this.warehouse = this.warehouses.find((item: IWarehouses): boolean => {
      return item['@id'] === this.warehouseForm.value.warehouse;
    });

    this.warehouseCarriers = [];

    if (!this.warehouse) {
      return;
    }

    this.warehouseCarrierResource.cGet({ 'warehouse.code': this.warehouse.code, pagination: false }, { isHydra: true, returnHydraMembers: true })
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any[]): void => {
        response.forEach((item: any) => {
          this.warehouseCarriers.push({
            '@id': item['@id'],
            'label': item.carrier.carrierGroup.name.toUpperCase() + ' / ' + item.carrier.country.code.toUpperCase(),
            'carrierCode': item.carrier.carrierGroup.code,
          });
        });

        this.buildCapacityForm();
      })
    ;
  }

  public submit(): void {
    const body: any = {
      warehouseCarriers: this.capacityForm.value.warehouseCarriers,
      date: this.capacityForm.value.date ? moment(this.capacityForm.value.date, DATE_SHORT_FORMAT).format(DATE_SHORT_FORMAT_UK) : null,
      dayOfWeek: this.capacityForm.value.dayOfWeek >= 0 ? this.capacityForm.value.dayOfWeek : null,
      daysOfExport: this.capacityForm.value.daysOfExport,
      capacity: this.capacityForm.value.capacity,
      pickup: !this.capacityForm.value.noPickup,
      scale: this.capacityForm.value.scale,
      carrierGroupAgencyCapacities: [],
    };

    this.agencyForms.forEach((agencyForm: FormGroup): void  => {
      if (agencyForm.value.agencyCapacity) {
        body.carrierGroupAgencyCapacities.push({
          carrierGroupAgency: agencyForm.value.agency,
          capacity: agencyForm.value.agencyCapacity,
        });
      }
    });

    const observable: Observable<object> = this.state.params.id
      ? this.carrierCapacityResource.update(this.state.params.id, body)
      : this.carrierCapacityResource.create(body)
    ;

    observable
      .pipe(takeUntil(this.destroyed$))
      .subscribe((): void => {
        this.snackbar.validate('SAVED');
        setTimeout((): void => { this.state.go('carrier.capacity'); }, 150);
      })
    ;
  }

  public setCapacity(): void {
    this.capacityForm.get('capacity').setValue(
      this.capacity && !this.capacityForm.get('noPickup').value && !this.capacityForm.get('scale').value ? this.capacity.capacity : 0
    );
  }

  public setInjection(): void {
    if (!this.warehouse || !this.capacityForm.value.warehouseCarriers.length || !this.capacityForm.get('injection').value) {
      this.carrierGroupAgencies = this.agencyForms = [];

      return;
    }

    this.fetchAgencies();
  }

  private fetchAgencies(): void {
    const carrierCodes: string[] = this.warehouseCarriers
      .filter((warehouseCarrier: any) => {
        return this.capacityForm.value.warehouseCarriers.includes(warehouseCarrier['@id']);
      })
      .map((warehouseCarrier: any) => {
        return warehouseCarrier.carrierCode;
      })
    ;

    if (!carrierCodes.length) {
      return;
    }

    this.carrierGroupAgencyResource
      .cGet(
        { 'warehouseCarrierGroupAgencies.warehouse.code': this.warehouse.code, 'carrierGroup.code[]': carrierCodes },
        { isHydra: true, returnHydraMembers: true, dontUseModel: true }
      )
      .pipe(takeUntil(this.destroyed$))
      .subscribe((response: any[]): void => {
        response.forEach((item: any): void => {
          const agency: any = this.capacity && this.capacity.carrierGroupAgencyCapacities.length
            ? this.capacity.carrierGroupAgencyCapacities.find((capacity: any): boolean => capacity.carrierGroupAgency['@id'] === item['@id'])
            : null
          ;

          this.agencyForms.push(this.formBuilder.group({
            agency: [item['@id']],
            code: [item.carrierGroup.name.toUpperCase() + ' - ' + item.agencyCode.toUpperCase()],
            agencyCapacity: [agency ? agency.capacity : null],
          }));
        });
      })
    ;
  }
}
