import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiscountResource } from '@components/discount/resources/discount.resource';
import { GenericFormModule, GenericModule } from '@components/generic';
import { SwitchModule } from '@progress/kendo-angular-inputs';
import { FormsModule } from '@angular/forms';
import { TabsModule } from 'ngx-bootstrap';
import { DirectivesModule } from '@directives/directives.module';
import { TooltipModule } from 'ngx-tooltip';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DiscountTranslationResource } from '@components/discount/resources/discount-translation.resource';
import {
  AutomaticDiscountListComponent,
  AutomaticDiscountResultListComponent
} from '@components/automatic-discount/list';
import {
  AutomaticDiscountFormComponent,
} from '@components/automatic-discount/form/automatic-discount-form.component';
import {
  AutomaticDiscountFormTranslationsComponent
} from '@components/automatic-discount/form/general/automatic-discount-form-translations.component';
import {AutomaticDiscountResource} from '@components/automatic-discount/resources/automatic-discount.resource';
import {AutomaticDiscountFiltersFormService} from '@components/automatic-discount/list/services/automatic-discount-filters-form.service';

@NgModule({
  declarations: [
    AutomaticDiscountListComponent,
    AutomaticDiscountResultListComponent,
    AutomaticDiscountFormComponent,
    AutomaticDiscountFormTranslationsComponent,
  ],
  entryComponents: [
    AutomaticDiscountListComponent,
    AutomaticDiscountFormComponent,
  ],
  providers: [
    AutomaticDiscountResource,
    DiscountResource,
    DiscountTranslationResource,
    AutomaticDiscountFiltersFormService,
  ],
  imports: [
    CommonModule,
    GenericModule,
    SwitchModule,
    FormsModule,
    GenericFormModule,
    TabsModule,
    DirectivesModule,
    TooltipModule,
    TimepickerModule.forRoot(),
    DialogsModule
  ]
})

export class AutomaticDiscountModule {}
